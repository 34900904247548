import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Button from 'react-bootstrap/Button';
import CallToAction from '../../molecules/CallToAction/CallToAction';

const Reports = ({ annualLoss = 0, results = [{}], onRestart }) => {
  const {
    data: { nodes },
  } = useStaticQuery(getData);

  const conversionData = useMemo(
    () =>
      nodes.filter(item =>
        item.internalName.toLowerCase().includes('no-show')
      )[0],
    [nodes]
  );

  const {
    callToAction,
    testimonial: { body, person },
  } = conversionData;

  return (
    <div className="reports-wrapper container">
      <p className="kicker">Total estimated annual losses due to no-shows</p>
      <p className="amount">-${annualLoss.toLocaleString()}</p>

      <div className="result-cards">
        {useMemo(
          () =>
            results.map(result => (
              <div className="report-card">
                <p className="loss-term">{result.title}</p>
                <p className="value">- ${result.value.toLocaleString()}</p>
              </div>
            )),
          [results]
        )}
      </div>

      <div className="testimonials">
        <div className="quote" dangerouslySetInnerHTML={{ __html: body }} />
        <p className="name">
          <strong>{`${person?.firstName} ${person?.lastName}`}</strong>
          ,&nbsp;
          {person?.company?.name}
        </p>
        <div className="buttons">
          <CallToAction
            variant="secondary"
            link={callToAction?.url}
            value={callToAction?.label}
          />
          <Button className="btn-restart border-0" onClick={onRestart}>
            Restart Calculator
          </Button>
        </div>
      </div>

      <p className="disclaimer">
        Disclaimer: The value provided by this calculator is intended to assist
        in seeking financial guidance and is not intended to replace financial
        advice. Valuations vary based on business conditions, geography and
        economic conditions among other reasons. Shopmonkey makes no warranty or
        representation as to the results of this calculation.
      </p>
    </div>
  );
};

export default Reports;

const getData = graphql`
  {
    data: allDatoCmsCalculatorCp {
      nodes {
        internalName
        testimonial {
          body
          person {
            id
            firstName
            lastName
            role
            company {
              id
              name
              location
            }
          }
        }
        callToAction {
          id
          internalName
          url
          label
        }
      }
    }
  }
`;
